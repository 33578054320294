<template>
  <div v-loading="wrapLoading"
       class="target-wrapper">
    <div class="target-item-table">
      <div class="item-info bottom-solid">
        <div class="target-warp">
          <div class="target-right">
            <div class="target-right-top">
              <p class="title">{{ targetItem.data.guideName }}</p>
              <el-button @click="targetItemEditButton()"
                         title="编辑指标配置信息"
                         type="primary"
                         icon="el-icon-edit"
                         size="mini"
                         plain></el-button>
            </div>
            <div class="target-describe">{{ targetItem.data.description }}</div>
            <div class="target-info">
              <span class="el-icon-office-building">{{ targetItem.data.copyFrom }}</span>
              <span class="el-icon-time">{{ targetItem.data.formatTime }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="item-title bottom-solid">
        <span>指标配置</span>
      </div>

      <div class="item-table">

        <el-table border
                  row-key="id"
                  default-expand-all
                  :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                  :data="targetItem.deployDirList">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="80">
          </el-table-column>

          <el-table-column prop="catalogueName"
                           label="目录"
                           min-width="200"></el-table-column>

          <el-table-column prop="catalogueCode"
                           label="编号"
                           align="center"
                           min-width="150"></el-table-column>

          <el-table-column label="内容"
                           align="center"
                           width="80">
            <template slot-scope="data">
              <el-button v-if="data.row.catalogueType === '1'"
                         type="text"
                         @click="checkTargetDetails(data)">查看</el-button>
              <div v-else>/</div>
            </template>
          </el-table-column>

          <el-table-column prop="sort"
                           label="排序"
                           align="center"
                           width="100">
            <template slot-scope="data">
              <template v-if="data.row.id">
                <template v-if="data.row.childrenLength > 1">
                  <el-button v-show="data.row.tier === 0 && data.row.tierIndex !== 0 ||
												data.row.tier === 1 && data.row.tierIndex1 !== 0 ||
												data.row.tier === 2 && data.row.tierIndex2 !== 0"
                             @click="handleEduTargetCatalogueSort(data)"
                             type="text">上移</el-button>
                  <el-button v-show="data.row.tier === 0 && data.row.tierIndex !== data.row.childrenLength - 1 ||
												data.row.tier === 1 && data.row.tierIndex1 !== data.row.childrenLength - 1 ||
												data.row.tier === 2 && data.row.tierIndex2 !== data.row.childrenLength - 1"
                             @click="handleEduTargetCatalogueSort(data, 1)"
                             type="text">下移</el-button>
                </template>
              </template>
            </template>
          </el-table-column>

          <el-table-column prop="userName"
                           label="创建人"
                           align="center"
                           width="100">
          </el-table-column>

          <el-table-column prop="createTime"
                           label="创建时间"
                           align="center"
                           width="180">
          </el-table-column>

          <el-table-column label="操作"
                           align="right"
                           width="300">
            <template slot-scope="data">
              <div v-if="data.row.id"
                   class="table-handle">
                <el-button v-if="data.row.catalogueType === '0'"
                           type="text"
                           @click="targetDeployDirTierUpdateButton(data)">编辑</el-button>
                <el-button v-if="data.row.catalogueType === '1'"
                           type="text"
                           @click="targetButton(data.row, 'update')">编辑</el-button>
                <i>|</i>

                <template v-if="targetTierButtonVisible(data, 1)">
                  <el-button @click="targetDeployDirTierInsertButton(data.row)"
                             type="text">添加同级</el-button>
                  <i>|</i>
                </template>

                <template v-if="targetTierButtonVisible(data, 2)">
                  <el-button @click="targetDeployDirTierInsertButton(data.row, 1)"
                             type="text">添加子级</el-button>
                  <i>|</i>
                </template>

                <template v-if="targetTierButtonVisible(data, 3)">
                  <el-button @click="targetButton(data.row, 'insert')"
                             type="text">添加目标</el-button>
                  <i>|</i>
                </template>

                <el-button type="text"
                           @click="targetTierButtonDelete(data.row)">删除</el-button>
              </div>
              <div v-else
                   class="table-handle">
                <el-button @click="targetDeployDirTierInsertButton('insert')"
                           type="text">添加</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 指标库添加窗口 -->
    <el-dialog :visible.sync="targetItem.visible"
               :title="targetItem.title"
               class="target-item-edit">
      <div class="content">
        <el-form ref="targetItem"
                 :model="targetItem.details"
                 :rules="targetItem.rules"
                 label-position="right"
                 label-width="5.20833vw">
          <el-form-item prop="code"
                        label="编号：">
            <el-input v-model="targetItem.details.code"
                      title="请填写编号名称，支持大小写字母、数字、下划线组合"
                      placeholder="请填写编号名称，支持大小写字母、数字、下划线组合"
                      type="text"
                      :maxlength="10"></el-input>
          </el-form-item>
          <el-form-item prop="guideName"
                        label="名称：">
            <el-input v-model="targetItem.details.guideName"
                      title="请输入名称"
                      placeholder="请输入名称"
                      type="text"
                      :minlength="2"
                      :maxlength="50"></el-input>
          </el-form-item>
          <el-form-item prop="copyFrom"
                        label="来源：">
            <el-input v-model="targetItem.details.copyFrom"
                      title="请输入来源"
                      placeholder="请输入来源"
                      type="text"
                      :maxlength="200"></el-input>
          </el-form-item>
          <el-form-item prop="publicTime"
                        label="发布时间：">
            <el-date-picker v-model="targetItem.details.publicTime"
                            type="date"
                            align="left"
                            placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item prop="description"
                        label="描述："
                        class="head-item-describe">
            <el-input v-model="targetItem.details.description"
                      show-word-limit
                      placeholder="请填写描述内容..."
                      type="textarea"
                      :maxlength="100"
                      class="dialog-target-description-after"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer"
           align="right">
        <el-button size="mini"
                   @click="targetItem.visible = false">取消</el-button>
        <el-button size="mini"
                   @click="targetItemAddSubmit()"
                   type="primary">保存</el-button>
      </div>
    </el-dialog>

    <!-- 添加目录层级 -->
    <el-dialog :title="targetDeployTier.dialogTitle"
               :visible.sync="targetTierVisibleVisible"
               class="target-item-edit">
      <div class="content">
        <el-form ref="targetDeployTier"
                 :rules="targetDeployRules"
                 :model="targetDeployTier"
                 label-position="right"
                 label-width="5.20833vw">
          <el-form-item label="编号："
                        prop="catalogueCode">
            <el-input v-model="targetDeployTier.catalogueCode"
                      title="请填写编号名称，支持大小写字母、数字、下划线组合"
                      placeholder="请填写编号名称，支持大小写字母、数字、下划线组合"
                      type="text"
                      :minlength="2"
                      :maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="名称："
                        prop="catalogueName">
            <el-input v-model="targetDeployTier.catalogueName"
                      title="请输入名称"
                      placeholder="请输入名称"
                      type="text"
                      minlength="2"
                      maxlength="50"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="targetDeployDirTierInsertClose">取 消</el-button>
        <el-button type="primary"
                   @click="targetDeployDirTierInsertSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加目标 -->
    <el-dialog :visible.sync="targetInsertVisible"
               :title="targetInsertData.dialogTitle"
               width="90%"
               class="target-insert">
      <div class="content">
        <el-steps :active="targetInsertData.step"
                  space="15.625vw"
                  align-center>
          <el-step title="1、基本信息"
                   icon="el-icon-success"></el-step>
          <el-step title="2、指标描述"
                   icon="el-icon-success"></el-step>
        </el-steps>
        <!-- 第一步 -->
        <el-form v-if="targetInsertData.step === 1"
                 ref="targetInsertData"
                 :rules="targetDeployRules"
                 :model="targetInsertData"
                 label-position="right"
                 label-width="5.20833vw">
          <div class="input-box">
            <el-form-item label="编号："
                          prop="catalogueCode">
              <el-input v-model="targetInsertData.catalogueCode"
                        title="请填写编号名称，支持大小写字母、数字、下划线组合"
                        placeholder="请填写编号名称，支持大小写字母、数字、下划线组合"
                        type="text"
                        minlength="2"
                        maxlength="10"></el-input>
            </el-form-item>
            <el-form-item label="名称："
                          prop="catalogueName">
              <el-input v-model="targetInsertData.catalogueName"
                        title="请输入名称"
                        placeholder="请输入名称"
                        type="text"
                        minlength="2"
                        maxlength="50"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="教育建议：">
            <el-table :data="targetInsertData.advice"
                      border>
              <el-table-column prop="targetInsertData"
                               type="index"
                               label="序号"
                               width="80"
                               align="center">
              </el-table-column>
              <el-table-column align="center"
                               prop="content"
                               label="一级">
                <template slot-scope="data">
                  {{data.row.index === 0 ? data.row.content : '\\'}}
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="content"
                               label="二级">
                <template slot-scope="data">
                  {{data.row.index === 1 ? data.row.content : '\\'}}
                </template>
              </el-table-column>
              <el-table-column align="center"
                               prop="content"
                               label="三级">
                <template slot-scope="data">
                  {{data.row.index === 2 ? data.row.content : '\\'}}
                </template>
              </el-table-column>
              <el-table-column align="center"
                               label="操作">
                <template slot-scope="data">
                  <el-button v-if="data.row.index !== 2"
                             @click="targetSuggestInsertButton(data)"
                             type="text"
                             postTargetSuggestInsert>添加</el-button>
                  <template v-if="data.row.id">
                    <el-button @click="targetSuggestEditButton(data)"
                               type="text">编辑</el-button>
                    <el-button @click="targetSuggestDeleteButton(data)"
                               type="text"
                               class="danger">删除</el-button>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

        </el-form>
        <!-- 第二步 -->
        <div v-else>
          <el-form :model="targetInsertData">
            <el-form-item label="指标维度：">
              <el-radio v-model="targetInsertData.dimensionality"
                        @change="targetDimensionalityChange"
                        label="0">按年龄阶段</el-radio>
              <el-radio v-model="targetInsertData.dimensionality"
                        @change="targetDimensionalityChange"
                        label="1">按课程等级</el-radio>
              <el-radio v-model="targetInsertData.dimensionality"
                        @change="targetDimensionalityChange"
                        label="2">不分维度</el-radio>
            </el-form-item>

            <el-form-item label="指标描述：">
              <el-tabs v-if="targetInsertData.dimensionality !== '2'"
                       v-model="targetInsertData.activeName"
                       type="card"
                       @tab-click="handleDictTab">
                <el-tab-pane v-for="item in targetInsertData.eduDictList"
                             :key="item.id"
                             :label="item.name"
                             :value="item.value"></el-tab-pane>
              </el-tabs>

              <template>
                <div class="table-width-calc">
                  <el-table :data="targetInsertData.targetDescribeTableDatas[targetInsertData.activeName]"
                            border>
                    <el-table-column prop="descriptionCode"
                                     label="编号：">
                    </el-table-column>
                    <el-table-column prop="descriptionContent"
                                     label="内容：">
                    </el-table-column>
                    <el-table-column prop="prop"
                                     label="操作：">
                      <template slot-scope="data">
                        <div v-if="!data.row.descriptionCode"
                             key="add">
                          <el-button type="text"
                                     @click="targetDescribeTableInsertButton(data)">添加</el-button>
                        </div>
                        <div v-else
                             key="edit">
                          <el-button type="text"
                                     @click="targetDescribeTableInsertButton(data, 'update')">编辑</el-button>
                          <el-button type="text"
                                     class="danger"
                                     @click="targetDescribeTableDeleteButton(data)">删除</el-button>
                        </div>
                      </template>

                    </el-table-column>
                    <el-table-column slot="header"
                                     prop="prop"
                                     label="label">
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </el-form-item>

            <el-form-item label="教育实践："
                          class="target-fulfil">
              <el-tabs v-model="targetInsertData.targetFulfilTabsNameValue"
                       type="card"
                       closable
                       @tab-click="targetFulfilTabClick"
                       @tab-remove="targetFulfilTabRemove">
                <el-tab-pane v-for="(item, index) in targetInsertData.targetFulfilTabs"
                             :key="index"
                             :label="item.tagName"
                             :id="item.id"
                             :name="index + ''"></el-tab-pane>
              </el-tabs>
              <div class="tabs-insert-button">
                <el-button @click="targetFulfilTabAdd"
                           icon="el-icon-edit"
                           title="添加标签"
                           plain></el-button>
              </div>
            </el-form-item>
          </el-form>
          <!-- 教学实践 -->
          <div class="target-fulfil-article">
            <!-- 标题及编辑、删除按钮 -->
            <template>
              <div v-for="(tag, i) in targetInsertData.targetFulfilTabs"
                   :key="i">
                <div v-for="(item, index) in target.fulfil.articleDatas[i]"
                     :key="index">
                  <div v-if="targetInsertData.targetFulfilTabsNameValue === tag.tagValue"
                       :key="index"
                       class="target-fulfil-article-item">
                    <div class="target-article-item-title">
                      <div class="title">{{item.practiceTitle}}</div>
                      <div class="edit">
                        <template>
                          <el-button @click="targetFulfilTabsArticleEdit(item, index)"
                                     type="text"
                                     icon="el-icon-edit"
                                     title="编辑文章"></el-button>
                          <el-button @click="targetFulfilTabsArticleDelete(item, index)"
                                     type="text"
                                     icon="el-icon-delete"
                                     class="danger"
                                     title="删除文章"></el-button>
                        </template>
                      </div>
                    </div>

                    <div class="target-article-item-content">
                      <div class="content"
                           v-html="item.practiceContent"></div>
                    </div>

                    <div class="practice-files-wrap">
                      <div v-for="(file, fileIndex) in item.files"
                           :key="fileIndex"
                           class="file-item">
                        <div>
                          <div class="file-item-header">
                            <div class="file-item-header-img">
                              <div v-if="checkFileType(file.originalName, 'pdf')"
                                   class="file-pdf"></div>
                              <div v-if="checkFileType(file.originalName, 'doc')"
                                   class="file-word"></div>
                              <div v-if="checkFileType(file.originalName, 'docx')"
                                   class="file-word"></div>
                              <div v-if="checkFileType(file.originalName, 'xlsx')"
                                   class="file-xlsx"></div>
                            </div>
                            <div class="file-item-header-name">{{file.originalName}}</div>
                          </div>
                          <div class="file-item-footer">
                            <template>
                              <el-button @click="targetFulfilUploadFileUpdate(file)"
                                         type="text"
                                         icon="el-icon-document"></el-button>
                              <el-button @click="targetArticleItemFilesDelete(item, index, fileIndex)"
                                         type="text"
                                         icon="el-icon-delete"></el-button>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div class="target-article-item-add">
                <el-button @click="targetFulfilArticleAddButton"
                           type="primary"
                           plain>添加</el-button>
              </div>
            </template>
          </div>
        </div>

      </div>
      <div slot="footer"
           align="right">
        <el-button v-if="targetInsertData.step === 2"
                   @click="targetInsertSava('prev')">上一步</el-button>
        <el-button @click="targetInsertClose">取消</el-button>
        <el-button v-if="targetInsertData.step === 1"
                   @click="targetInsertSava('next')"
                   type="primary">下一步</el-button>
        <el-button v-if="targetInsertData.step === 2"
                   @click="targetInsertSava('save')"
                   type="primary">保存</el-button>
      </div>
    </el-dialog>

    <!-- 添加标签 -->
    <el-dialog title="添加标签"
               :visible.sync="targetInsertData.targetFulfilTabsVisible"
               class="target-item-edit">
      <div class="content">
        <el-form ref="targetInsertTab"
                 :rules="targetInsertData.tabAddRules"
                 :model="targetInsertData.tabAdd"
                 label-position="right"
                 label-width="5.20833vw">
          <el-form-item label="标签："
                        prop="tagName">
            <el-input v-model="targetInsertData.tabAdd.tagName"
                      title="请输入标签名称"
                      placeholder="请输入标签名称"
                      type="text"
                      :minlength="2"
                      :maxlength="10"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="targetInsertData.targetFulfilTabsVisible = false">取消</el-button>
        <el-button type="primary"
                   @click="targetFulfilTabAddSubmit">确定</el-button>
      </div>
    </el-dialog>

    <!-- 教育建议、指标描述 内容输入框 :v-loading="targetDescribeDialog.loading"-->
    <el-dialog :title="targetDescribeDialog.title"
               :visible.sync="targetDescribeDialog.visible"
               class="target-item-edit">
      <div class="content">
        <el-form label-width="4.16667vw"
                 ref="targetDescribeDialog"
                 :model="targetDescribeDialog"
                 :rules="targetDescribeDialogRules">
          <el-form-item prop="content"
                        label="内容："
                        class="dialog-target-description-after">
            <el-input v-model="targetDescribeDialog.content"
                      type="textarea"
                      placeholder="500白字以内"
                      :maxlength="500"
                      show-word-limit></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="targetDescribeDialog.visible = false">取消</el-button>
        <el-button type="primary"
                   @click="targetSuggestInsertButtonSubmit">确定</el-button>
      </div>
    </el-dialog>

    <!-- 添加教学实践对话框 -->
    <el-dialog title="添加教学实践"
               :visible.sync="target.fulfil.dialog.buttonAddVisible"
               @close="dialogPracticeClose"
               class="dialog-target-fulfil-add"
               width="80%">
      <div>
        <el-form ref="targetPracticeInsert"
                 :model="target.fulfil.dialog.form"
                 :rules="target.fulfil.dialog.targetPracticeInsertRules"
                 label-width="4.16667vw">
          <el-form-item prop="practiceTitle"
                        label="标题：">
            <el-input v-model="target.fulfil.dialog.form.practiceTitle"
                      type="text"
                      placeholder="请填写名称，支持汉字、字母、数字组合"></el-input>
          </el-form-item>
          <el-form-item prop="practiceContent"
                        label="内容：">
            <editor-component style="width: 100%"
                              ref="courseContentComponent"
                              :id="'courseContent'"
                              :height="'300'"
                              :defaultContent="editorContent"
                              @getContent="getContent"></editor-component>
          </el-form-item>
          <el-form-item label="附件：">
            <div class="target-fulfil-upload-file">
              <el-button icon="el-icon-upload"
                         @click="targetFulfilUploadFile">本地上传</el-button>
              <span class="size-padding">或</span>
              <el-input v-model="target.fulfil.dialog.form.practiceUrl"
                        type="text"
                        placeholder="输入附件链接地址"
                        :maxlength="500"></el-input>
              <el-button @click="targetFulfilPracticeUrlAdd"
                         icon="el-icon-plus"
                         class="add">添加</el-button>
            </div>
          </el-form-item>

          <div class="target-fulfil-upload-point">支持格式：.pdf .ppt .pptx .doc .docx .xls .xlsx .png .jpg .mp4 .mp3<span>单个文件超过500MB请通过链接地址的方式添加附件</span>，添加附加数量不限。
          </div>

          <div>
            <div class="target-fulfil-files">
              <div v-for="(item, index) in target.fulfil.dialog.form.files"
                   :key="index"
                   class="target-fulfil-files-item">
                <div class="file">
                  <div class="type">{{splitSuffix(item.originalName)}}</div>
                  <div class="file-name">{{item.originalName}}</div>
                  <div class="file-remove">
                    <el-button @click="targetFulfilUploadFileDelete(item, index)"
                               type="text"
                               icon="el-icon-delete"
                               class="danger">删除</el-button>
                  </div>
                </div>
                <div class="download">
                  <el-radio-group v-model="item.isDownload">
                    <el-radio label="0">不支持下载</el-radio>
                    <el-radio label="1">支持下载</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div slot="footer"
           align="right">
        <el-button @click="target.fulfil.dialog.buttonAddVisible = false">取消</el-button>
        <el-button @click="targetFulfilPracticeAddButton"
                   type="primary">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="预览"
               :visible.sync="filePreviewDialog.visible"
               class="target-item-edit file-preview-dialog">
      <img v-if="filePreviewDialog.type === 1"
           :src="filePreviewDialog.url">
      <video v-if="filePreviewDialog.type === 2"
             :src="filePreviewDialog.url"
             controls></video>
    </el-dialog>

    <target-details :targetDetailsData="targetDetailsData"
                    :targetDetailsVisible="targetDetailsVisible"
                    :targetDetailsLoading="targetDetailsLoading"></target-details>
  </div>
</template>

<script>
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
  ["clean"], // remove formatting button
];
import EditorComponent from "@/components/editorComponent/index.vue";
import targetDetails from "./targetDetails.vue";
import tool from '@/utils/tool';
export default {
  name: 'targetCollocate',
  components: {
    EditorComponent,
    targetDetails
  },
  data () {
    return {
      filePreviewDialog: {
        visible: false,
        url: '',
        type: 0,
      },
      editorContent: '',//编辑器内容
      targetContent: '',//编辑框值临时存字段
      wrapLoading: false, //外框loading
      pageLoading: null, //页面loading
      //子组件目标详情id
      targetDetailsData: {},
      targetDetailsVisible: false,
      targetDetailsLoading: false,
      targetInsertVisible: false,
      targetDescribeDialog: {
        loading: false,
        title: '添加教育建议',
        visible: false,
        content: '',

      },
      targetDescribeDialogRules: {
        content: [{ required: true, message: "内容不能为空" }]
      },
      indicatorId: 0, //指标库ID
      targetTierVisibleVisible: false, //添加｜修改目录弹窗 显隐
      target: { //目标数据
        data: {},
        fulfil: {
          arciletAdd: [],
          articleDatas: [],
          dialog: {
            buttonAddVisible: false,
            targetPracticeInsertRules: {
              practiceTitle: [
                { required: true, message: "请输入名称，支持汉字、字母、数字组合" },
                { min: 2, max: 50, message: "2-50个汉字长度" }
              ]
            },
            form: {
              practiceTitle: '',
              // practiceContent: '',
              files: [],
              isDownload: '0'
            }
          }
        }
      },
      targetDeployTier: {
        catalogueCode: '',
        catalogueName: '',
        dialogTitle: ''
      },
      targetDeployRules: {
        catalogueCode: [
          {
            pattern: /^[0-9a-zA-Z_]*$/,
            message: "只支持大小写字母、数字、下划线组合"
          },
          { required: true, message: "请填写编号名称" }
        ],
        catalogueName: [
          { required: true, message: "请输入名称，支持汉字、字母、数字组合" },
          { min: 2, max: 50, message: "2-50个汉字长度" }
        ]
      },
      targetItem: {
        visible: false, //指标配置详情修改窗口显隐
        title: '',
        data: { //指标库配置详情
          code: '',
          guideName: '',
          copyFrom: '',
          publicTime: '',
          description: ''
        },
        details: {}, //指标库配置详情数据
        deployDirList: [],
        rules: { //data 验证格式
          code: [
            { pattern: /^[0-9a-zA-Z_]*$/, message: "只支持大小写字母、数字、下划线组合" },
            { min: 2, max: 10, message: "2-10个汉字长度" }
          ],
          guideName: [
            { required: true, message: "请输入名称，支持汉字、字母、符号、数字组合" },
            { min: 2, max: 50, message: "2-50个汉字长度" }
          ],
          copyFrom: [
            { min: 2, max: 15, message: "2-15个汉字长度" }
          ],
          description: { max: 100, message: "100个汉字长度" }
        },
        targetItemEditTitle: "",
        targetListTableData: [], //指标列表数据存放

      },
      targetInsertData: {
        step: 1,
        dialogTitle: '',
        activeName: '',
        catalogueCode: '', //添加目标-编号
        catalogueName: '', //添加目标-标题
        targetInsertLoading: false,//添加目标显隐
        advice: [
          {}
        ],
        adviceIndex: 0,
        targetDescribeTableDatas: [[{}]],
        targetDescribeTableDataIndexs: [],
        tabName: '',
        tabAdd: {
          tagName: '',
        },
        tabAddRules: {
          tagName: { required: true, message: "请输入名称，支持汉字、字母、数字组合" }
        },
        dimensionality: '0',
        //字典返回列表
        eduDictList: [],
        //字典名字
        eduDictName: ['age_scope', 'class_level', ''],
        targetFulfilTabsVisible: false,
        targetFulfilTabsIndex: '0',
        targetFulfilTabsValue: '0',
        targetFulfilTabsNameValue: '0',
        //教学实践标签
        targetFulfilTabs: [],
        targetFulfiTabsData: []
      },
      targetDeployTableData: [],
      uploadUrl: "", //上传url
      editorOption: { //富文本配置
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  alert("自定义图片");
                } else {
                  this.quill.format("image", false);
                }
              },
            }
          },
        },
        placeholder: '',
      }
    }
  },
  created () {
    let { id } = this.$route.params;
    this.target.targetId = id;
    //获取指标库配置详情
    this.$api.getTarget(id)
      .then(res => {
        if (res.data.code) {
          this.$message.error(res.data.msg);
          return;
        }
        let { data } = res.data;
        this.targetItem.data = data;
        data.formatTime = data.publicTime;
      })
      .catch(e => {
        this.$message.error('指标库配置详情获取错误');
        console.error(e);
      })


    //获取指标库配置列表
    this.$api.getTargetDeployDirList({ id })
      .then(res => {
        console.log(res);
        if (res.data.code) {
          this.$message.error(res.data.msg);
          return;
        }
        let { data } = res.data;

        this.setIndicatorConfiguration(data);

      })
      .catch(e => {
        this.$message.error('指标配置列表获取错误');
        console.error(e);
      });
  },
  methods: {
    /**
     * 指标库-编辑按钮
     * @param {type} data
     */
    targetItemEditButton (data) {
      this.targetItem.title = "编辑";
      this.targetItem.status = 'update';
      this.targetItem.details = Object.assign({}, this.targetItem.data);
      this.targetItem.details.publicTime = new Date(this.targetItem.data.publicTime);
      this.targetItem.visible = true;
    },
    //指标库添加/编辑-提交
    targetItemAddSubmit (status) {
      this.$refs['targetItem'].validate(v => {
        if (v) {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '操作中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          let { details } = this.targetItem;
          console.log(details);
          if (status) {
            this.targetItem.status = status;
          }
          status = this.targetItem.status === 'insert' ? '添加' : '修改';
          //时间格式转换
          details.publicTime = tool.formatTime(details.publicTime);
          this.$api[status === '添加' ? 'postTargetItem' : 'putTargetItem'](details)
            .then(res => {
              console.log(res);
              this.pageLoading.close()
              if (res.data.code) {
                this.$message.error(res.data.msg);
                return;
              }

              this.$nextTick(_ => {
                let { guideName, description, copyFrom, formatTime } = details;
                this.targetItem.data.guideName = guideName;
                this.targetItem.data.description = description;
                this.targetItem.data.copyFrom = copyFrom;
                this.targetItem.data.formatTime = formatTime;
                this.targetItem.visible = false;
                this.$refs['targetItem'].resetFields();
                this.$message.success(`指标库${status}成功`);
              });

            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error(`指标库${status}错误`);
              console.error(e);
            })
            .finally(_ => {

            });
        }
      });
    },
    // 指标配置按钮筛选
    targetTierButtonVisible (data, n) {
      data = data.row;
      if (data.indexs) return data.indexs[n];
      //n = 0指标 1同级 2子级 3目标
      if (data.catalogueType === '0') {
        //目录
        if (data.parentCatalogueId) {//2级
          data.indexs = [0, 1, 0, 1];
        } else {//一级目录
          if (data.children && data.children.length) {
            if (data.children[0].catalogueType === '1') { //有目标
              data.indexs = [0, 1, 0, 1];
            } else {
              data.indexs = [0, 1, 1, 0];
            }
          } else {
            data.indexs = [0, 1, 1, 1];
          }

        }
      } else {
        //目标
        data.indexs = [0, 0, 0, 0];
      }
      return data.indexs[n];
    },
    /**
     * 目录 编辑按钮
     */
    targetDeployDirTierUpdateButton (data) {
      let { id, catalogueType, parentCatalogueId, targetGuideId, catalogueCode, catalogueName } = data.row;
      this.targetTierVisibleVisible = true;
      this.$nextTick(() => {
        this.targetDeployTier.id = id;
        this.targetDeployTier.catalogueType = catalogueType;
        this.targetDeployTier.parentCatalogueId = parentCatalogueId;
        this.targetDeployTier.targetGuideId = targetGuideId;
        this.targetDeployTier.catalogueCode = catalogueCode;
        this.targetDeployTier.catalogueName = catalogueName;
        this.targetDeployTier.catalogueInfo = data.row;
        this.targetDeployTier.dialogStatus = 'update';
        this.targetDeployTier.dialogTitle = '编辑目录层级';
      })
    },

    // 添加目录层级
    targetDeployDirTierInsertButton (data, tier = null) {
      this.targetTierVisibleVisible = true;
      this.$refs.targetDeployTier && this.$refs.targetDeployTier.resetFields();
      this.$nextTick(() => {
        this.targetDeployTier.dialogStatus = 'insert';
        this.targetDeployTier.dialogTitle = '添加目录层级';
        if (data === 'insert') {
          data = {};
          data.targetGuideId = this.target.targetGuideId;
        } else {
          this.targetDeployTier.catalogueCode = '';
          this.targetDeployTier.catalogueName = '';
        }
        let { targetGuideId, id } = data;
        this.targetDeployTier.targetGuideId = targetGuideId;
        this.targetDeployTier.parentCatalogueId = tier ? id : null;
        //添加同级目录时，判断是否是二级目录，是的话把父id保存
        if (data.parentCatalogueId) {
          this.targetDeployTier.parentCatalogueId = data.parentCatalogueId;
        }
        this.targetDeployTier.catalogueType = 0;
      })
    },
    //添加目录层级关闭
    targetDeployDirTierInsertClose () {
      this.targetTierVisibleVisible = false;
      this.targetDeployTier = {
        catalogueCode: '',
        catalogueName: '',
        dialogTitle: '',
      };
      this.$nextTick(() => {
        this.$refs['targetDeployTier'].clearValidate();
      })
    },
    //添加目录层级提交
    targetDeployDirTierInsertSubmit () {

      this.$refs['targetDeployTier'].validate(v => {
        if (v) {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '操作中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          let { id,
            catalogueCode,
            catalogueName,
            targetGuideId,
            parentCatalogueId,
            catalogueType } = this.targetDeployTier;
          //编辑目录层级
          if (this.targetDeployTier.dialogStatus === 'update') {
            this.$api.putTargetDeployDirUpdate({ id, catalogueCode, catalogueName, targetGuideId, parentCatalogueId, catalogueType })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code) {
                  this.$message.error(res.data.msg);
                  return;
                }
                this.targetDeployDirTierInsertClose();
                this.$api.getTargetDeployDirList({ id: this.target.targetId })
                  .then(res => {
                    console.log(res);
                    if (res.data.code) {
                      this.$message.error(res.data.msg);
                      return;
                    }
                    let { data } = res.data;
                    this.setIndicatorConfiguration(data);
                  })
                  .catch(e => {
                    this.$message.error('指标配置列表获取错误');
                    console.log(e);
                  })
                this.$message.success('目录层级修改成功');
              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('目录层级修改错误');
                console.error(e);
              })
            return;
          }
          //添加目录层级
          if (!targetGuideId) {
            targetGuideId = this.target.targetId;
          }
          this.$api.postTargetDeployDirInsert({
            targetGuideId,
            parentCatalogueId,
            catalogueCode,
            catalogueName,
            catalogueType
          })
            .then(res => {
              this.pageLoading.close()
              if (res.data.code) {
                this.$message.error(res.data.msg);
                return;
              }
              //添加目录后重新获取目录列表
              this.setTargetDeployList();
              // this.$api.getTargetDeployDirList({ id: targetGuideId })
              // 	.then(res => {
              // 		if (res.data.code) {
              // 			this.$message.error(res.data.msg);
              // 			return;
              // 		}
              // 		this.setIndicatorConfiguration(res.data.data);
              // 		this.$refs['targetDeployTier'].resetFields();
              // 	})
              // 	.catch(e => {
              // 		this.$message.error('目录列表获取错误');
              // 		console.error(e);
              // 	})
              this.targetTierVisibleVisible = false;
              this.$message.success('目录层级添加成功');

            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('目录层级添加错误');
              console.error(e);
            })

        }
      });
    },
    //目录层级删除
    targetTierButtonDelete (data) {
      console.log(data);
      this.buttonDelete()
        .then(_ => {
          console.log(_);
          let { id } = data;
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '删除中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.deleteTargetDeployDir(id)
            .then(res => {
              this.pageLoading.close()
              if (res.data.code === 0) {
                //目标id
                let id = this.target.targetId;
                this.$api.getTargetDeployDirList({ id })
                  .then(res => {
                    if (res.data.code === 0) {
                      this.targetItem.deployDirList = res.data.data;
                      return;
                    }
                    this.$message.error(res.data.msg);
                  })
                  .catch(e => {
                    this.$message.error('指标配置列表获取错误');
                    console.log(e);
                  });
                this.$message.success('目录层级删除成功');
                return;
              }

              this.$message.error(res.data.msg);
            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('目录层级删除错误');
              console.error(e);
            });
        })
        .catch(_ => {
          console.log(_);
        });
    },
    // 添加/编辑 目标按钮
    targetButton (data, status) {
      let self = this;
      //清除窗口数据
      console.log(data);
      this.targetInsertVisible = true;
      this.targetInsertData.targetFulfilTabs = [];
      this.targetInsertData.step = 1;
      this.targetInsertData.stepStatus = 'next'
      this.target.parentCatalogueId = data.id;
      this.targetDescribeDialog.catalogueCode = data.catalogueCode;
      this.targetInsertData.catalogueInfo = data;

      if (status === 'insert') {
        this.targetInsertData.dialogStatus = 'insert';
        this.targetInsertData.dialogTitle = '添加目标';
        this.targetDescribeDialog.status = 1;
        console.log(this.$refs);
        this.$refs.targetInsertData && this.$refs.targetInsertData.resetFields();
        //初始化数据
        //保存状态
        this.targetInsertData.saveStatus = 0;
        //初始化 编号、名称
        this.targetInsertData.catalogueCode = '';
        this.targetInsertData.catalogueName = '';

        //初始化 教育建议
        this.targetInsertData.advice = [{}];
        this.targetDescribeDialog.targetInfoId = null;
        console.log(data);
      } else {
        //编辑
        let { id } = data;

        //是二级目录编辑的话
        ////
        ///
        this.targetInsertData.dialogStatus = 'update';
        this.targetInsertData.dialogTitle = '编辑目标';
        this.targetInsertData.advice = [{}];
        this.targetDescribeDialog.status = 0;


        this.targetInsertData.catalogueCode = data.catalogueCode;
        this.targetInsertData.catalogueName = data.catalogueName;
        this.targetInsertData.catalogueType = data.catalogueType;
        //获取教育建议分级，并赋予index便于渲染数据
        this.$api.getTargetMultistageSuggest({ id })
          .then(res => {
            if (res.data.code) {
              if (res.data.code !== 1) {
                return this.$message.error('教育建议获取失败');
              } else {
                return this.$message.error(res.data.msg);
              }
            }

            let { data } = res.data;

            if (data) {
              let advices = [];

              data.map((item, index) => {
                let advice = item;
                advice.index = 0;
                advices.push(advice);

                if (advice = advice.children) {
                  advice = advice.map(item1 => {
                    item1.index = 1;
                    advices.push(item1);
                    if (item1.children) {
                      item1.children.map(item2 => {
                        item2.index = 2;
                        advices.push(item2);
                      });

                    }
                  });
                }
              });

              this.targetInsertData.advice.splice(0);
              this.targetInsertData.advice.push(...advices, {});

            }


          })
          .catch(e => {
            this.$message.error('教育建议获取错误');
            console.error(e);
          })

        this.$api.getTargetMultistageSuggestDetails({ id })
          .then(res => {
            if (res.data.code) {
              if (res.data.code !== 1) {
                return this.$message.error('目标详情获取失败');
              } else {
                return this.$message.error(res.data.msg);
              }
            }

            let { data } = res.data;

            if (data) {
              this.targetDescribeDialog.targetInfo = data.info;
              this.targetDescribeDialog.targetInfoId = data.info.id;
            }

          })
          .catch(e => {
            this.$message.error('目标详情获取错误');
            console.error(e);
          })
      }

    },
    targetInsertClose () {
      this.targetInsertVisible = false;
    },
    /**
     * 查看目标详情
     */
    checkTargetDetails (data) {
      let { row } = data;
      let { id } = row;
      this.targetDetailsVisible = true;
      this.targetDetailsLoading = true;
      this.$api.getTargetDetails({ id })
        .then(res => {
          if (res.data.code) {
            this.$message.error('res.data.msg');
            return;
          }
          this.targetDetailsData = res.data.data;
        })
        .catch(e => {
          this.$message.error('目标详情获取错误');
          console.error(e);
        })
        .finally(_ => {
          this.targetDetailsLoading = false;
        })
    },
    /**
     * 指标目录上移下移
     * @param {object} data
     * @param {object} status false上移,true下移
     */
    handleEduTargetCatalogueSort (data, status) {
      console.log(data);
      let { tier, tierIndex, tierIndex1, tierIndex2 } = data.row;
      let items = data.store.states.data;
      let item;
      let id, sort;
      let moveId, moveSort;
      let index = data.row.index;
      let index1;
      item = items[tierIndex];

      if (status) {
        index1 = index + 1;
      } else {
        index1 = index - 1;
      }

      if (typeof tierIndex1 === 'number') {
        items = item.children;
        item = items[tierIndex1];
        if (typeof tierIndex2 === 'number') {
          items = item.children;
          item = item[tierIndex2];
        }
      }

      id = data.row.id;
      sort = data.row.sort;
      moveId = items[index1].id;
      moveSort = items[index1].sort;

      this.$api.postTargetSort({
        id1: id,
        sort1: sort,
        id2: moveId,
        sort2: moveSort
      })
        .then(res => {
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }

          this.$api.getTargetDeployDirList({ id: this.target.targetId })
            .then(res => {
              console.log(res);
              if (res.data.code) {
                this.$message.error(res.data.msg);
                return;
              }
              this.$message.success('移动成功');
              let { data } = res.data;
              this.target.targetGuideId = id;
              this.setIndicatorConfiguration(data);
            })
            .catch(e => {
              this.$message.error('指标配置列表获取错误');
              console.log(e);
            })
        })
        .catch(e => {

        })
      // items.splice(index, 1, ...items.splice(index1, 1, items[index]));


    },
    /**
     * 添加/编辑 目标-下一步
     */
    targetInsertSava (type) {
      if (this.targetInsertData.step === 2 && type == 'save') {
        this.targetInsertVisible = false;
        if (this.targetInsertData.dialogStatus === 'insert') {
          this.$message.success('目标添加成功');
        }
        return;
      }
      if (type == 'prev') {
        this.targetInsertData.advice = [{}];
        this.targetDescribeDialog.status = 0;

        let { id } = this.targetInsertData.catalogueInfo

        this.targetInsertData.stepStatus = 'prev';
        this.targetInsertData.step = 1

        //获取教育建议分级，并赋予index便于渲染数据
        this.$api.getTargetMultistageSuggest({ id })
          .then(res => {
            if (res.data.code) {
              if (res.data.code !== 1) {
                return this.$message.error('教育建议获取失败');
              } else {
                return this.$message.error(res.data.msg);
              }
            }

            let { data } = res.data;

            if (data) {
              let advices = [];

              data.map((item, index) => {
                let advice = item;
                advice.index = 0;
                advices.push(advice);

                if (advice = advice.children) {
                  advice = advice.map(item1 => {
                    item1.index = 1;
                    advices.push(item1);
                    if (item1.children) {
                      item1.children.map(item2 => {
                        item2.index = 2;
                        advices.push(item2);
                      });

                    }
                  });
                }
              });

              this.targetInsertData.advice.splice(0);
              this.targetInsertData.advice.push(...advices, {});
            }


          })
          .catch(e => {
            this.$message.error('教育建议获取错误');
            console.error(e);
          })

        this.$api.getTargetMultistageSuggestDetails({ id })
          .then(res => {
            if (res.data.code) {
              if (res.data.code !== 1) {
                return this.$message.error('目标详情获取失败');
              } else {
                return this.$message.error(res.data.msg);
              }
            }

            let { data } = res.data;

            if (data) {
              this.targetDescribeDialog.targetInfo = data.info;
              this.targetDescribeDialog.targetInfoId = data.info.id;
            }

          })
          .catch(e => {
            this.$message.error('目标详情获取错误');
            console.error(e);
          })
        return;
      }
      this.$refs.targetInsertData.validate(v => {
        if (v) {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '操作中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.targetInsertData.dimensionality = '0';
          this.targetInsertData.activeName = '0';
          this.targetInsertData.eduDictIndex = '0';

          if (this.targetInsertData.dialogStatus === 'insert' && !this.targetDescribeDialog.targetInfoId) {
            let suggestList = [];
            let { catalogueCode, catalogueName } = this.targetInsertData;
            let { targetGuideId, id } = this.targetInsertData.catalogueInfo;
            let parentCatalogueId = id;
            //把教育建议id取出 放入数组
            this.targetInsertData.advice.filter(item => {
              if (item.id && !item.save) {
                suggestList.push(item.id);
              }
            });

            this.$api.postTargetSaveNext({
              catalogueCode,
              catalogueName,
              targetGuideId,
              parentCatalogueId,
              suggestList
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code) {
                  this.$message.error(res.data.msg);
                  return;
                }
                this.targetInsertData.step = 2;
                let { data } = res.data;
                //把返回id设置为内容id
                this.targetDescribeDialog.targetInfoId = data.id;
                this.targetInsertData.catalogueInfo.id = data.targetCatalogueId
                this.targetInsertData.stepStatus = type
                this.targetDimensionalityChange('0');
                this.setTargetDeployList();
                this.$message.success('目标添加成功');

              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('目标基本信息保存错误');
                console.error(e);
              });
          } else {
            let { catalogueCode, catalogueName } = this.targetInsertData;
            let { id, catalogueType, parentCatalogueId, targetGuideId } = this.targetInsertData.catalogueInfo;

            this.$api.putTargetDeployDirUpdate({
              id,
              catalogueCode,
              catalogueName,
              catalogueType,
              parentCatalogueId,
              targetGuideId
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code) {
                  this.$message.error(res.data.msg);
                  return;
                }
                console.log(this.targetInsertData.catalogueInfo);
                console.log(res);
                this.targetInsertData.catalogueInfo.catalogueCode = catalogueCode;
                this.targetInsertData.catalogueInfo.catalogueName = catalogueName;
                this.targetInsertData.step = 2;
                this.targetInsertData.stepStatus = type
                this.targetDimensionalityChange('0');
              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('目标基本信息保存错误');
                console.error(e);
              });

          }
        }
      });

    },
    //指标维度单选
    targetDimensionalityChange (value) {
      this.targetInsertData.eduDictIndex = value;
      this.targetInsertData.activeName = '0';
      this.targetEduDict(value);
    },
    //指标维度查询
    targetEduDict (flag) {
      //内容id
      let id = this.targetDescribeDialog.targetInfoId;
      this.$api.getTargetMultistageSuggestDetails_2({ id, flag })
        .then(res => {
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }
          let { data } = res.data;
          let { tags, practices, dict } = data;

          //指标维度下字典标签数据
          this.targetInsertData.eduDictList.splice(0);
          if (dict) {
            this.targetInsertData.eduDictList.push(...dict);
          }


          //指标描述数据
          this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName].splice(0);
          this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName].push(...data.desctions, {});

          console.log(tags)
          //教育实践标签数据
          this.setEducationalPracticeTagsData(tags);

          //教育实践数据
          this.setEducationalPracticeData(practices);

        })
        .catch(e => {
          this.$message.error('指标描述详情获取错误');
          console.error(e);
        })
    },
    //教育实践标签点击
    targetFulfilTabClick (data) {
      console.log(data);
      //编辑状态
      if (1 || this.targetInsertData.dialogStatus === 'update') {
        let id = this.targetDescribeDialog.targetInfoId;
        let flag = this.targetInsertData.dimensionality;
        let typeValue = this.targetInsertData.activeName;
        let tagId = data.$attrs.id;

        this.targetInsertData.targetFulfilTabsId = tagId;
        this.targetInsertData.targetFulfilTabsIndex = data.index;
        this.targetInsertData.targetFulfilTabsNameValue = data.index + '';

        this.$api.getTargetTagPractice({ id, flag, typeValue, tagId })
          .then(res => {
            if (res.data.code) {
              this.$message.error(res.data.msg);
              return;
            }
            console.log(res.data.data);
            this.setEducationalPracticeData(res.data.data);
          })
          .catch(e => {
            this.$message.error('教育实践标签数据获取错误');
            console.error(e);
          })
        return;
      }

    },
    // 教育实践删除标签
    targetFulfilTabRemove (typeValue) {
      console.log(typeValue);
      this.buttonDelete()
        .then(_ => {
          let id;
          let index;

          this.targetInsertData.targetFulfilTabs.filter((item, i) => {
            if (item.tagValue === typeValue) {
              id = item.id;
              index = i;
              return;
            }
          });
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '删除中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.deleteTargetTag(id)
            .then(res => {
              this.pageLoading.close()
              if (res.data.code === 0) {
                this.$message.success('教育实践标签删除成功');
                this.targetInsertData.targetFulfilTabs.splice(index, 1);
                this.target.fulfil.articleDatas.splice(index, 1);
                return;
              }
              console.log(res);
              this.$message.error(res.data.msg);
            })
            .catch(e => {
              this.pageLoading.close()
              console.error(e);
              this.$message.error('教育实践标签删除错误');
            })
        })
        .catch(_ => {

        })
    },
    // 教育实践添加标签
    targetFulfilTabAdd () {
      this.targetInsertData.tagsStatus = 0;
      this.targetInsertData.targetFulfilTabsVisible = true;
      this.targetInsertData.tabAdd.tagName = '';
      this.$refs['targetInsertTab'] && this.$refs['targetInsertTab'].resetFields();
    },
    //教育实践添加标签提交按钮
    targetFulfilTabAddSubmit () {
      this.$refs['targetInsertTab'].validate(v => {
        if (v) {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '操作中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          if (this.targetInsertData.tagsStatus === 0) {
            let { activeName } = this.targetInsertData;
            let { targetInfoId } = this.targetDescribeDialog;
            let flag = this.targetInsertData.dimensionality;
            let typeValue = null
            let tagName = this.targetInsertData.tabAdd.tagName;
            if (flag != '2') {
              typeValue = this.targetInsertData.eduDictList[activeName].value;
            }
            this.$api.postTargetTag({
              targetInfoId,
              flag,
              typeValue,
              tagName
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code === 0) {
                  let tagsIndex = this.targetInsertData.targetFulfilTabs.length;
                  this.targetInsertData.targetFulfilTabsVisible = false;
                  this.targetInsertData.targetFulfilTabsId = res.data.data.id;
                  this.targetInsertData.targetFulfilTabsNameValue = tagsIndex + '';
                  this.targetInsertData.targetFulfilTabsValue = typeValue;
                  this.targetInsertData.targetFulfilTabsIndex = this.targetInsertData.targetFulfilTabs.length;
                  res.data.data.tagValue = tagsIndex + '';
                  this.targetInsertData.targetFulfilTabs.push(res.data.data);
                  this.target.fulfil.articleDatas.splice(this.targetInsertData.targetFulfilTabsIndex, 1, []);
                  this.$refs['targetInsertTab'].resetFields();
                  this.$message.success('教育实践标签添加成功');
                  return;
                }
                console.log(res);
                this.$message.error(res.data.msg);
              })
              .catch(e => {
                this.pageLoading.close()
                console.error(e);
                this.$message.error('教育实践标签添加错误');
              })
              .finally(_ => {
              })
          }
        }
      })
    },

    //教育实践 添加网络链接
    targetFulfilPracticeUrlAdd () {
      let fileUrl = this.target.fulfil.dialog.form.practiceUrl;
      if (fileUrl) {
        // .pdf, .ppt, .doc, .docx, .xlsx, .png, .jpg, .mp4, .mp3'
        let fileMatch = fileUrl.match(/^https?:\/\/(.+)\.(pdf|ppt|doc|docx|xlsx|png|jpg|jpeg|mp4|mp3)$/);
        // download 1不支持下载 2支持下载
        if (fileMatch) {
          let fileName = fileMatch[1].split('/');
          if ((fileName[fileName.length - 1] + '.' + fileMatch[2]).length > 200) {
            this.$message.error('网络链接文件名称的长度不能超过200字');
            return
          }
          this.target.fulfil.dialog.form.files.push({
            originalName: fileName[fileName.length - 1] + '.' + fileMatch[2],
            type: fileUrl[2],
            fileUrl: fileMatch[0],
            isDownload: '0'
          });
          this.$message.success('获取文件成功');
          this.target.fulfil.dialog.form.practiceUrl = '';
        } else {
          this.$message.error('请输入正确的网络链接文件');
        }
      } else {
        this.$message.error('网络链接不能为空');
      }

    },
    /**
     * 检查是否为参数的格式
     * @param {string} type 文件后缀格式
     * @return {array|null} 返回匹配到的格式
     */
    checkFileType (type, strType) {
      let match = type.match(/(pdf|doc|docx|xlsx|word)/);
      if (match) {
        strType = strType.toLowerCase();
        if (match[1] === strType) {
          return 1;
        }
      }
      return 0;
    },
    //设置获取指标配置列表
    setTargetDeployList () {
      this.$api.getTargetDeployDirList({ id: this.target.targetId })
        .then(res => {
          console.log(res);
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }
          let { data } = res.data;
          this.setIndicatorConfiguration(data);
        })
        .catch(e => {
          this.$message.error('指标配置列表获取错误');
          console.log(e);
        })
    },
    /**
     * 指标配置列表 设置
     */
    setIndicatorConfiguration (data) {
      if (data.length) {

        data.map((item, index) => {
          item.tier = 0;
          item.index = index;
          item.tierIndex = index;
          item.childrenLength = data.length;
          if (item.children) {
            item.children.map((item1, index1) => {
              item1.tier = 1;
              item1.index = index1;
              item1.tierIndex = index;
              item1.tierIndex1 = index1;
              item1.childrenLength = item.children.length;

              if (item1.children) {
                item1.children.map((item2, index2) => {
                  item2.tier = 2;
                  item2.index = index2;
                  item2.tierIndex = index;
                  item2.tierIndex1 = index1;
                  item2.tierIndex2 = index2;
                  item2.childrenLength = item1.children.length;
                  return item2;
                });
              }

              return item1;
            });

          }
          return item;
        });
        this.targetItem.deployDirList = [];
        this.targetItem.deployDirList.push(...data);
      } else {
        this.targetItem.deployDirList = [{}];
      }
    },
    // 添加/编辑 指标描述
    targetDescribeTableInsertButton (data, type = 'insert') {
      this.targetDescribeDialog.visible = true;
      if (type === 'insert') {
        this.$refs['targetDescribeDialog'] && this.$refs['targetDescribeDialog'].resetFields();
        this.targetDescribeDialog.status = 2;
        this.targetDescribeDialog.title = '添加指标描述';
        this.targetDescribeDialog.content = '';
      } else if (type === 'update') {
        this.targetDescribeDialog.status = 3;
        this.targetDescribeDialog.title = '编辑指标描述';
        this.targetDescribeDialog.index = data.$index
        this.targetDescribeDialog.content = data.row.descriptionContent;
      }

    },
    // 删除 指标描述按钮
    targetDescribeTableDeleteButton (data) {
      this.buttonDelete()
        .then(_ => {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '删除中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.deleteTargetDescription(data.row.id)
            .then(res => {
              this.pageLoading.close()
              if (res.data.code === 0) {
                this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName].splice(data.$index, 1);
                this.$message.success('指标描述删除成功');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('指标描述删除错误');
              console.error(e);
            })
        })
        .catch(e => { });
    },
    //编辑教育建议内容
    targetSuggestEditButton (data) {
      this.targetDescribeDialog.status = 0;
      this.targetDescribeDialog.visible = true;
      this.targetDescribeDialog.title = '编辑教育建议';
      this.targetDescribeDialog.content = data.row.content;
      this.targetDescribeDialog.suggestId = data.row.id;
      this.targetDescribeDialog.index = data.$index;
    },
    //添加教育建议内容按钮
    targetSuggestInsertButton (data) {
      this.targetDescribeDialog.status = 1;
      this.targetDescribeDialog.visible = true;
      this.targetDescribeDialog.title = '添加教育建议';
      this.targetDescribeDialog.content = '';
      this.$refs['targetDescribeDialog'] && this.$refs['targetDescribeDialog'].resetFields();
      //设置内容级别
      this.targetDescribeDialog.$index = data.$index + 1;
      if (data.row.id) {
        this.targetDescribeDialog.parentSuggestId = data.row.id;
        this.targetDescribeDialog.index = data.row.index + 1;
        if (data) {
          for (let i = this.targetDescribeDialog.$index, item = this.targetInsertData.advice; i < item.length; i++) {
            if (item[i].content) {
              if (this.targetDescribeDialog.index === 2) {
                if (item[i].index === 1 || item[i].index === 0) {
                  this.targetDescribeDialog.$index = i - 1;
                  break;
                }
              } else if (this.targetDescribeDialog.index === 1) {
                if (item[i].index === 0) {
                  this.targetDescribeDialog.$index = i - 1;
                  break;
                } else if (item[i].index === undefined) {
                  break;
                }
              }
              this.targetDescribeDialog.$index = i;
            }
          }
          console.log(this.targetInsertData.advice[this.targetDescribeDialog.$index].content);
          console.log(this.targetInsertData.advice);
          console.log(this.targetInsertData.advice[this.targetDescribeDialog.$index]);
          return;
        }
      } else {
        this.targetDescribeDialog.parentSuggestId = null;
        this.targetDescribeDialog.index = 0;
      }

    },
    //添加教育建议内容按钮-提交
    targetSuggestInsertButtonSubmit () {
      /**
       * this.targetDescribeDialog.status
       * 0 编辑教育建议
       * 1 添加教育建议
       * 2 添加指标描述
       * 3 编辑指标描述
       */
      if (this.targetDescribeDialog.status === 0) {
        let id = this.targetDescribeDialog.suggestId;
        let content = this.targetDescribeDialog.content;
        this.$refs['targetDescribeDialog'].validate(v => {
          if (v) {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '操作中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$api.putTargetSuggestUpdate({
              id,
              content
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code === 0) {
                  let { data } = res.data;
                  this.targetInsertData.advice[this.targetDescribeDialog.index].content = data.content;
                  this.targetDescribeDialog.visible = false;
                  this.$message.success('教育建议修改成功');
                  this.$refs['targetDescribeDialog'].resetFields();
                  return;
                }
                this.$message.error(res.data.msg);
              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('教育建议修改错误');
                console.error(e);
              });
          }
        })

      } else if (this.targetDescribeDialog.status === 1) {
        //添加教育建议
        this.$refs['targetDescribeDialog'].validate(v => {
          if (v) {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '操作中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let { targetInfoId, parentSuggestId, content } = this.targetDescribeDialog;
            this.$api.postTargetSuggestInsert({
              targetInfoId,
              parentSuggestId,
              content
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code === 0) {
                  let { data } = res.data;
                  let pop = this.targetInsertData.advice.pop();
                  data.index = this.targetDescribeDialog.index;
                  if (parentSuggestId) {
                    this.targetInsertData.advice.splice(this.targetDescribeDialog.$index + 1, 0, data);
                  } else {
                    this.targetInsertData.advice.push(data);
                  }

                  this.targetInsertData.advice.push(pop);
                  this.$refs['targetDescribeDialog'].resetFields();
                  this.$message.success('教育建议内容添加成功');
                  this.targetDescribeDialog.visible = false;
                  return;
                }
                this.$message.error(res.data.msg);
                this.targetDescribeDialog.visible = false;
                console.log(res);
              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('教育建议内容添加错误');
                this.targetDescribeDialog.visible = false;
                console.error(e);
              });
          }
        });

      } else if (this.targetDescribeDialog.status === 2) {
        //添加目标描述
        this.$refs['targetDescribeDialog'].validate(v => {
          if (v) {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '操作中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let targetInfoId = this.targetDescribeDialog.targetInfoId;
            let flag = this.targetInsertData.dimensionality;
            let typeValue;
            let descriptionContent = this.targetDescribeDialog.content;
            if (this.targetInsertData.eduDictList.length) {
              typeValue = this.targetInsertData.eduDictList[this.targetInsertData.activeName].value;
            }
            this.$api.postTargetDescription({
              targetInfoId,
              flag,
              typeValue,
              descriptionContent
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code === 0) {
                  let { data } = res.data;

                  //判断指标描述 字典列表数组是否有index下标，没有则填充0
                  if (typeof this.targetInsertData.targetDescribeTableDataIndexs[this.targetInsertData.activeName] !== 'number') {
                    this.targetInsertData.targetDescribeTableDataIndexs[this.targetInsertData.activeName] = 0;
                  }
                  //指标描述 编号自动拼接下标
                  this.targetInsertData.targetDescribeTableDataIndexs[this.targetInsertData.activeName]++;
                  data.catalogueCode = this.targetInsertData.catalogueCode + this.targetInsertData.targetDescribeTableDataIndexs[this.targetInsertData.activeName];
                  //把返回的的数据（新添加的）写入保存数据的数组;
                  this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName].splice(-1, 0, data);
                  this.targetDescribeDialog.visible = false;
                  this.$message.success('指标描述添加成功');
                  this.$refs['targetDescribeDialog'].resetFields();
                  return;
                }
                this.$message.error(res.data.msg);
              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('指标描述添加错误');
                console.error(e);
              });
          }
        })
      } else if (this.targetDescribeDialog.status === 3) {
        this.$refs['targetDescribeDialog'].validate(v => {
          if (v) {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '操作中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let _data = this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName][this.targetDescribeDialog.index];
            _data.descriptionContent = this.targetDescribeDialog.content;
            let { id, descriptionContent } = _data;
            this.$api.putTargetDescription({
              id,
              descriptionContent
            })
              .then(res => {
                this.pageLoading.close()
                if (res.data.code === 0) {
                  this.targetDescribeDialog.visible = false;
                  this.$message.success('指标描述修改成功');
                  this.$refs['targetDescribeDialog'].resetFields();
                  return;
                }
                this.$message.error(res.data.msg);
              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('指标描述修改错误');
                console.error(e);
              });
          }
        });

      }
    },
    //教育建议删除按钮
    targetSuggestDeleteButton (data) {
      let { id } = data.row;
      this.buttonDelete()
        .then(_ => {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '删除中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.deleteTargetSuggest(id)
            .then(res => {
              this.pageLoading.close()
              if (res.data.code === 0) {
                this.targetInsertData.advice.splice(data.$index, 1);
                this.$message.success('教育建议删除成功');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('教育建议删除错误');
              console.error(e);
            })
        })
        .catch(_ => {
          console.log(_);
        });
    },
    // 上传文件
    targetFulfilUploadFile (e, type = 'file') {
      let self = this;
      let file = document.createElement('input');
      if (type === 'file') {
        file.type = type;
        file.accept = '.pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx, .png, .jpg, .mp4, .mp3';
      }
      file.click();
      file.addEventListener('change', function () {
        if (this.files.length === 0) return;
        let formData = new FormData();
        let originalFileName = this.files[0].name;
        if (originalFileName.length > 200) {
          self.$message.error('附件名称不能大于200字');
          return;
        }
        console.log(this.files[0]);
        formData.append('file', this.files[0]);
        // let loading = self.$loading({ fullscreen: true });
        if (type === 'file') {
          self.pageLoading = self.$loading({
            lock: true,
            fullscreen: true,
            text: '上传中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          console.log(this.files[0]);
          let fileType = this.files[0].name.match(/\.([\w]{3,4})$/);
          let fnName = '';
          switch (fileType[1]) {
            case 'mp3':
            case 'mp4':
              fnName = 'uploadFileVideo';
              self.$api[fnName](formData)
                .then(res => {
                  self.pageLoading.close()
                  if (res.data === '上传失败') {
                    self.$message.error('附件上传失败');
                    return;
                  }
                  let fileUrl = res.data;
                  fileUrl = fileUrl.match(/^https?:\/\/(.+)\.([\w]{3,4})$/);
                  // download 1不支持下载 2支持下载
                  if (fileUrl) {
                    let fileName = fileUrl[1].split('/');
                    self.target.fulfil.dialog.form.files.push({
                      originalName: originalFileName,
                      type: fileUrl[2],
                      fileUrl: fileUrl[0],
                      isDownload: '0'
                    });
                  }
                  self.$message.success('附件上传成功');

                })
                .catch(e => {
                  self.pageLoading.close()
                  self.$message.error('附件上传错误');
                  console.error(e);
                })
                .finally(_ => {
                  loading.close();
                })
              break;
            default:
              fnName = 'uploadFile';
              // debugger
              self.$api[fnName](formData)
                .then(res => {
                  console.log(res);
                  if (res.data === '上传失败') {
                    self.$message.error('附件上传失败');
                    return;
                  }
                  let { fileId, fileUrl, fileVersionId } = res.data.data;
                  // download 1不支持下载 2支持下载
                  if (fileUrl) {
                    self.target.fulfil.dialog.form.files.push({
                      originalName: originalFileName,
                      fileId,
                      fileUrl,
                      fileVersionId,
                      isDownload: '0'
                    });
                  }
                  self.$message.success('附件上传成功');

                })
                .catch(e => {
                  self.pageLoading.close()
                  self.$message.error('附件上传错误');
                  console.error(e);
                })
                .finally(_ => {
                  self.pageLoading.close()
                })
              break;
          }
          // self.$api[fnName](formData)
          //   .then(res => {
          //     self.pageLoading.close()
          //     if (res.data === '上传失败') {
          //       self.$message.error('附件上传失败');
          //       return;
          //     }
          //     let fileUrl = res.data;
          //     fileUrl = fileUrl.match(/^https?:\/\/(.+)\.([\w]{3,4})$/);
          //     // download 1不支持下载 2支持下载
          //     if (fileUrl) {
          //       let fileName = fileUrl[1].split('/');
          //       self.target.fulfil.dialog.form.files.push({
          //         originalName: originalFileName,
          //         type: fileUrl[2],
          //         fileUrl: fileUrl[0],
          //         isDownload: '0'
          //       });
          //     }
          //     self.$message.success('附件上传成功');
          //   })
          //   .catch(e => {
          //     self.pageLoading.close()
          //     self.$message.error('附件上传错误');
          //     console.error(e);
          //   })
          //   .finally(_ => {
          //     loading.close();
          //   })
        }
      });

    },
    //教学实践上传文件后的删除按钮
    targetFulfilUploadFileDelete (data, index) {
      if (data.id) {
        this.buttonDelete()
          .then(_ => {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '删除中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$api.deleteFile(data.id)
              .then(res => {
                this.pageLoading.close()
                if (res.data.code) {
                  this.$message.error(res.data.msg);
                  return;
                }
                this.target.fulfil.dialog.form.files.splice(index, 1);
                this.$message.success('删除成功');

              })
              .catch(e => {
                this.pageLoading.close()
                this.$message.error('删除错误');
                console.error(e);
              })
          })
          .catch(_ => {

          });
      } else {
        this.$message.success('删除成功');
        this.target.fulfil.dialog.form.files.splice(index, 1);
      }

    },
    /**
     * 教学实践dialog关闭回调
     */
    dialogPracticeClose () {
      this.editorContent = ' ';
      this.targetContent = '';
      this.target.fulfil.dialog.form.practiceTitle = '';
      // this.target.fulfil.dialog.form.practiceContent = '';
      this.target.fulfil.dialog.form.files = [];
      this.target.fulfil.dialog.buttonAddVisible = false;
      this.$refs['targetPracticeInsert'].clearValidate();
    },
    //教学实践添加文章的添加 保存按钮
    targetFulfilPracticeAddButton () {
      //修改文章
      if (this.target.fulfil.dialog.articleStatus === 'update') {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '操作中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let { practiceTitle, files } = this.target.fulfil.dialog.form; //practiceContent,
        let id = this.target.fulfil.dialog.articleId;
        this.$api.putTargetPracticeUpdate({
          id,
          practiceTitle,
          practiceContent: this.targetContent,
          files
        })
          .then(res => {
            this.pageLoading.close()
            if (res.data.code) {
              this.$message.error(res.data.msg);
              return;
            }
            let index = this.target.fulfil.dialog.articleIndex;
            let { data } = res.data;
            let acticle = this.target.fulfil.articleDatas[this.targetInsertData.targetFulfilTabsIndex][index];
            acticle.practiceTitle = data.practiceTitle;
            acticle.practiceContent = data.practiceContent;
            acticle.files = data.files;
            this.target.fulfil.dialog.form.practiceTitle = '';
            // this.target.fulfil.dialog.form.practiceContent = '';
            this.target.fulfil.dialog.form.files = [];
            this.target.fulfil.dialog.buttonAddVisible = false;
            this.$refs.targetPracticeInsert.resetFields();
            this.$message.success('教育实践文章修改成功');
          })
          .catch(e => {
            this.pageLoading.close()
            this.$message.error('教育实践文章修改错误');
            console.error(e);
          })
        return;
      }
      //添加文章
      let { targetInfoId } = this.targetDescribeDialog;
      let targetTagId = this.targetInsertData.targetFulfilTabsId;
      let flag = this.targetInsertData.dimensionality;
      let typeValue = this.targetInsertData.targetFulfilTabsValue;
      let { practiceTitle, files } = this.target.fulfil.dialog.form;//practiceContent,

      this.$refs.targetPracticeInsert.validate(v => {
        if (v) {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '操作中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.postTargetPracticeInsert({
            targetInfoId,
            targetTagId,
            flag,
            typeValue,
            practiceTitle,
            practiceContent: this.targetContent,
            files
          })
            .then(res => {
              this.pageLoading.close()
              if (res.data.code === 0) {
                let fulfilIndex = this.targetInsertData.targetFulfilTabsIndex;

                if (!Array.isArray(this.target.fulfil.articleDatas[fulfilIndex])) {
                  this.target.fulfil.articleDatas.splice(fulfilIndex, 1, []);
                }

                this.target.fulfil.articleDatas[fulfilIndex].push(res.data.data);
                this.target.fulfil.dialog.buttonAddVisible = false;
                this.target.fulfil.dialog.form = {
                  practiceTitle: '',
                  // practiceContent: '',
                  files: [],
                }
                this.$message.success('教育实践文章添加成功');
                return;
              }
              this.$message.error(res.data.msg);
              console.log(res);
            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('教育实践文章添加错误');
              console.log(e);
            })
        }
      })
    },
    //教育实践标签下的添加文章按钮
    targetFulfilArticleAddButton () {
      if (this.targetInsertData.targetFulfilTabs.length == 0) {
        this.$message.error("请先添加标签")
        return
      }
      this.target.fulfil.dialog.buttonAddVisible = true;
      this.target.fulfil.dialog.articleStatus = 'insert';
      this.editorContent = '';
      this.targetContent = '';
      this.target.fulfil.dialog.form = {
        practiceTitle: '',
        // practiceContent: '',
        files: []
      };
      console.log(this.target.fulfil.dialog.form);
      this.$refs.targetPracticeInsert && this.$refs.targetPracticeInsert.resetFields();
      console.log(this.$refs);

    },
    //教育实践标签文章编辑
    targetFulfilTabsArticleEdit (data, index) {
      let form = this.target.fulfil.dialog.form;
      let { practiceTitle, practiceContent, files } = data;
      form.practiceTitle = practiceTitle;
      // form.practiceContent = practiceContent;
      form.files = files;
      this.editorContent = practiceContent;
      this.targetContent = practiceContent;
      this.target.fulfil.dialog.buttonAddVisible = true;
      this.target.fulfil.dialog.articleStatus = 'update';
      this.target.fulfil.dialog.articleId = data.id;
      this.target.fulfil.dialog.articleIndex = index;

    },
    //教育实践标签文章删除
    targetFulfilTabsArticleDelete (data, index) {
      this.buttonDelete()
        .then(_ => {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '删除中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.deleteTargetPractice(data.id)
            .then(res => {
              this.pageLoading.close()
              if (res.data.code === 0) {
                this.$message.success('教育实践文章删除成功');
                this.target.fulfil.articleDatas[this.targetInsertData.targetFulfilTabsIndex].splice(index, 1);
                return;
              }
              this.$message.error(res.data.msg);
              console.log(res);
            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('教育实践文章删除错误');
              console.error(e);
            })

        })
        .catch(_ => _);
      console.log(data);
    },
    //教育实践文章下 文件删除按钮
    targetArticleItemFilesDelete (data, index, fileIndex) {
      let tagIndex = this.targetInsertData.targetFulfilTabsIndex;
      let file = data.files[fileIndex];
      // if (!tagIndex) {
      // 	tagIndex = '0';
      // }
      this.buttonDelete()
        .then(_ => {
          this.pageLoading = this.$loading({
            lock: true,
            fullscreen: true,
            text: '删除中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$api.deleteFile(file.id)
            .then(res => {
              this.pageLoading.close()
              if (res.data.code) {
                this.$message.error(res.data.msg);
                console.log(res);
                return;
              }
              this.target.fulfil.articleDatas[tagIndex][index].files.splice(fileIndex, 1);
              this.$message.success('删除成功');

            })
            .catch(e => {
              this.pageLoading.close()
              this.$message.error('删除错误');
              console.error(e);
            })
        })
        .catch(e => {

        });

    },
    handleDictTab () {
      //字典typeValue值
      let { activeName } = this.targetInsertData;
      let id = this.targetDescribeDialog.targetInfoId;
      let flag = this.targetInsertData.dimensionality;
      let typeValue = this.targetInsertData.eduDictList[activeName].value;
      this.targetInsertData.targetDescribeTableDatas[activeName] = [{}];
      this.$api.getTargetTagDict({ id, flag, typeValue })
        .then(res => {
          if (res.data.code) {
            return this.$message.error(res.data.msg);
          }

          let { data } = res.data;

          if (data) {
            // this.targetDescribeTagsSwitch(null, 1);
            this.targetDescribeTagsSwitch(data);
          }
        })
        .catch(e => {
          this.$message.error('标签指标描述获取错误');
          console.error(e);
        })
    },
    /**
     * 指标描述字典标签切换，获取数据，处理数据结构;
     * clear为true时不获取数据，只清空所关联数据组
     * @param {Array} data 数据
     * @param {Boolean} clear 清空状态
     */
    targetDescribeTagsSwitch (data, clear) {
      //清空数据
      if (clear) {
        //清空指标描述
        this.setIndicatorDescriptionData(null, clear);
        //清空教育实践标签
        this.setEducationalPracticeTagsData(null, clear);
        //清空教育实践数据
        this.setEducationalPracticeData(null, clear);
        return;
      }

      let { desctions, tags, practices } = data;
      this.setIndicatorDescriptionData(desctions);
      this.setEducationalPracticeTagsData(tags);
      this.setEducationalPracticeData(practices);
    },
    /**
     * 指标描述数据 设置
     * clear为true时为清除数据
     * @param {array} data
     * @param {boolean} clear
     */
    setIndicatorDescriptionData (data, clear) {
      if (clear) {
        this.targetInsertData.targetDescribeTableDatas = [];
        this.targetInsertData.targetDescribeTableDatas.push([{}]);
        return;
      }
      this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName] = [];
      if (data) {
        this.targetInsertData.targetDescribeTableDatas[this.targetInsertData.activeName].push(...data, {});
      }
    },
    /**
     * 教育实践标签数据 设置
     * clear为true时为清除数据
     * @param {array} data
     * @param {boolean} clear
     */
    setEducationalPracticeTagsData (data, clear) {
      this.targetInsertData.targetFulfilTabs = [];
      this.targetInsertData.targetFulfilTabsValue = '0';
      this.targetInsertData.targetFulfilTabsNameValue = '0';
      this.targetInsertData.targetFulfilTabsIndex = 0;

      if (clear) return;

      let id = null;

      data = data.map((item, index) => {
        item.tagValue = index.toString();
        return item;
      });

      if (data) {
        this.targetInsertData.targetFulfilTabs.push(...data);
      }

      if (data.length) {
        id = data[0].id;
      }

      this.targetInsertData.targetFulfilTabsId = id;

    },
    /**
     * 教育实践数据 设置
     * clear为true时为清除数据
     * @param {array} data
     * @param {boolean} clear
     */
    setEducationalPracticeData (data, clear) {
      //教育实践当前下标
      console.log(data);

      this.$nextTick(_ => {
        let index = this.targetInsertData.targetFulfilTabsIndex;

        this.target.fulfil.articleDatas.splice(index, 1, []);

        if (clear) return;

        if (data) {
          if (data.length) {
            console.log(data);
            this.targetInsertData.targetFulfilTabsValue = data[0].typeValue;
            this.target.fulfil.articleDatas[index].push(...data);
          }
        }
      });

    },


    /**
     * 列表排序替换
     * @param {object} data
     * @param {boolean} status false为上移,true为下移
     */
    handleSort (data, status) {
      let index = data.$index;
      let items = data.store.states.data;
      let moveItem;
      let { id, sort } = data.row;
      let moveId, moveSort;

      if (items.length < 2) return;
      status ? index++ : --index;
      moveItem = items[index];
      moveId = moveItem.id;
      moveSort = moveItem.sort;

      this.$api.postEdutargetGuideSort({
        id1: id,
        sort1: sort,
        id2: moveId,
        sort2: moveSort,
      })
        .then(res => {
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }

          items.splice(data.$index, 1, ...items.splice(index, 1, items[data.$index]));
          this.$message.success('排序替换成功');
        })
        .catch(e => {
          this.$message.error('排序替换错误');
          console.error(e);
        })
    },
    /**
     * 指标目录上移下移
     * @param {object} data
     * @param {object} status false上移,true下移
     */
    handleEduTargetCatalogueSort (data, status) {
      console.log(data);
      let { tier, tierIndex, tierIndex1, tierIndex2 } = data.row;
      let items = data.store.states.data;
      let item;
      let id, sort;
      let moveId, moveSort;
      let index = data.row.index;
      let index1;
      item = items[tierIndex];

      if (status) {
        index1 = index + 1;
      } else {
        index1 = index - 1;
      }

      if (typeof tierIndex1 === 'number') {
        items = item.children;
        item = items[tierIndex1];
        if (typeof tierIndex2 === 'number') {
          items = item.children;
          item = item[tierIndex2];
        }
      }

      id = data.row.id;
      sort = data.row.sort;
      moveId = items[index1].id;
      moveSort = items[index1].sort;

      this.$api.postTargetSort({
        id1: id,
        sort1: sort,
        id2: moveId,
        sort2: moveSort
      })
        .then(res => {
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }

          this.$api.getTargetDeployDirList({ id: this.target.targetId })
            .then(res => {
              console.log(res);
              if (res.data.code) {
                this.$message.error(res.data.msg);
                return;
              }
              this.$message.success('移动成功');
              let { data } = res.data;
              this.target.targetGuideId = id;
              this.setIndicatorConfiguration(data);
            })
            .catch(e => {
              this.$message.error('指标配置列表获取错误');
              console.log(e);
            })
        })
        .catch(e => {

        })
      // items.splice(index, 1, ...items.splice(index1, 1, items[index]));


    },
    /**
     * 检查是否为参数的格式
     * @param {string} type 文件后缀格式
     * @return {array|null} 返回匹配到的格式
     */
    checkFileType (type, strType) {
      // let match = type.match(/(pdf|doc|docx|xlsx|word)/);
      let match = type.match(/\.([A-z0-9]{3,4})$/);
      if (match) {
        strType = strType.toLowerCase();
        if (match[1] === strType) {
          return 1;
        }
      }
      return 0;
    },
    targetDeployDirEdit (data) {
      this.targetInsertVisible = true;
      this.targetDescribeDialog.status = 0;
      let { id } = data;
      console.log(data);
      this.$api.getTargetMultistageSuggest({ id })
        .then(res => {
          console.log(res);
        })
        .catch(e => {
          console.log(e);
        })
      console.log(data);

    },
    targetFulfilUploadFileUpdate (file) {
      console.log(file);
      // let fileType = file.fileUrl.match(/\.(\w+)$/);
      // 		console.log(fileType);
      // return;
      // filePreviewDialog
      let fileType = file.fileUrl.match(/\.(\w+)$/);
      fileType = fileType[1];
      switch (fileType) {
        case 'jpg':
        case 'png':
          this.filePreviewDialog.type = 1;
          this.filePreviewDialog.visible = true;
          break;
        case 'mp3':
        case 'mp4':
          this.filePreviewDialog.type = 2;
          this.filePreviewDialog.visible = true;
          break;
        default:
          this.filePreviewDialog.type = 3;
      }
      this.filePreviewDialog.url = file.fileUrl;
      if (this.filePreviewDialog.type === 3) {
        switch (file.isDownload) {
          case '0':
            this.$message.warning('文件设置不可下载');
            break;
          case '1':
            let a = document.createElement('a');
            a.href = file.fileUrl;
            a.click();
            a = null;
        }

      }


    },
    //设置获取指标配置列表
    setTargetDeployList () {
      this.$api.getTargetDeployDirList({ id: this.target.targetId })
        .then(res => {
          console.log(res);
          if (res.data.code) {
            this.$message.error(res.data.msg);
            return;
          }
          let { data } = res.data;
          this.setIndicatorConfiguration(data);
        })
        .catch(e => {
          this.$message.error('指标配置列表获取错误');
          console.log(e);
        })
    },
    // 删除窗口
    buttonDelete ({ message } = { message: '是否确定删除数据？' }) {
      message = `${message}<br><span>是否确认删除该条数据，删除后，将无法恢复。</span>`
      return this.$confirm("", "操作确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "message-box",
        iconClass: "el-icon-question",
        dangerouslyUseHTMLString: true,
        message
      });
    },
    splitSuffix (str) {
      //所选文件的文件名
      let s = str;
      str = str.match(/\.([a-z]+)$/i);

      if (str && str.length >= 2) {
        // 返回文件名后缀
        return str[1];
      }
      return s;
    },
    getContent (data) {
      // this.target.fulfil.dialog.form.practiceContent = data.content;
      this.targetContent = data.content
    }
  }
}
</script>

<style lang="scss" scoped>
$colorGrey: #999;
$colorGrey_2: #666;
$colorGrey_3: #e9e9e9;
$borderRadius: 5px;

/deep/.el-dialog {
  border-radius: $borderRadius;
  overflow: hidden;
  .el-dialog__body {
    padding: 0;
    .el-dialog__title {
      color: $colorGrey_2;
    }
    .content {
      padding: 30px 70px 30px 60px;
    }
  }
  .footer {
    padding: 20px;
    border-top: 1px solid #ececec;
    .el-button {
      padding: 8px 25px;
    }
  }
}

.target-item-edit {
  .el-textarea {
    textarea {
      height: 120px;
    }
  }
}

.message-box {
  width: 570px;
  .el-message-box__header {
    padding: 18px 14px;
    background: #f5f5f5;
    box-sizing: border-box;
    .el-message-box__title {
      color: $colorGrey_2;
      font-size: 14px;
      font-weight: 700;
      .el-message-box__headerbtn {
        top: 18px;
      }
      .el-icon-close {
        color: $colorGrey_2;
        font-weight: bold;
      }
    }
  }

  .el-message-box__content {
    padding: 60px 40px;
    .el-message-box__container {
      .el-icon-question {
        color: #ff9900;
      }
    }
    .el-message-box__message {
      p {
        color: $colorGrey_2;
        font-size: 16px;
        > span {
          color: $colorGrey;
          font-size: 14px;
        }
      }
    }
  }

  .el-message-box__btns {
    padding: 15px 20px;
    border-top: 1px solid #eaeaea;
    .el-button {
      padding: 8px 20px;
    }
  }

  .box-wrap {
    display: flex;
    .icon {
      font-size: 2em;
      color: #ff9900;
    }
    .text {
      > p {
        color: $colorGrey_2;
        font-size: 16px;
      }
      > span {
        color: $colorGrey;
        font-size: 14px;
      }
    }
  }
}

.target-item-edit {
  .el-textarea {
    textarea {
      height: 120px;
    }
  }
}

.target-wrapper {
  padding: 20px;
  background: #fff;
  .item-title {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    &:after {
      bottom: 1px;
      height: 1px;
    }
    span {
      position: relative;
      &:after {
        z-index: 10;
        position: absolute;
        left: 50%;
        bottom: 0;
        content: '';
        width: 24px;
        height: 3px;
        background: #617def;
        transform: translate(-50%, 20px);
      }
    }
  }

  .bottom-solid:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    content: '';
    width: calc(100% + 12px * 2);
    height: 1px;
    background: #f5f6fa;
    transform: translateX(-12px);
  }

  .item-info {
    $info-solid-width: 20px;
    position: relative;
    margin-bottom: $info-solid-width;
    padding-bottom: $info-solid-width;
    &.bottom-solid:after {
      bottom: -20px;
      height: $info-solid-width;
    }
  }

  .target-button {
    margin-bottom: 16px;
  }

  .target-table {
    position: relative;
    .table-handle {
      i {
        padding: 0 4px;
        color: $colorGrey_3;
      }
    }
  }

  .target-pagination {
    padding: 40px 0;
  }
  .el-pagination.is-background {
    .btn-prev,
    .btn-next,
    .el-pager li {
      background: #fff;
      border: 1px solid $colorGrey_3;
    }
  }
}

.target-item-table {
  position: relative;
  .target-warp {
    width: 100%;
    display: flex;
    .img {
      flex-shrink: 0;
      width: 180px;
      height: 180px;
      background: #efefef;
      border-radius: $borderRadius;
      overflow: hidden;
    }
    .target-right {
      flex-grow: 1;
      padding-left: 15px;
      .target-right-top {
        position: relative;
        padding: 10px 0;
        width: 100%;
        height: 30px;
        line-height: 30px;
        p.title {
          display: inline-block;
          padding-right: 80px;
          font-size: 18px;
        }
        > span {
          margin-left: 20px;
          font-size: 14px;
          color: $colorGrey;
        }
        .el-button {
          position: absolute;
          right: 0;
        }
      }
      .target-describe {
        font-size: 14px;
        color: $colorGrey;
        line-height: 32px;
      }
      .target-info {
        margin-top: 20px;
        font-size: 14px;
        color: $colorGrey;
        > span {
          margin-right: 20px;
        }
      }
    }
  }
  .item-table {
    border-right: 1px solid #ebeef5;
  }

  .item-table.bottom-solid {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .table-handle {
    .el-button {
      margin: 2px;
    }
  }
}
/deep/.target-insert {
  .input-box {
    input {
      width: 600px;
    }
  }
  .el-steps {
    justify-content: center;
    .el-step {
      padding: 20px 0 40px;
    }
  }
  .el-dialog__footer {
    padding: 10px 40px 40px;
  }
  .el-tabs {
    padding-left: 70px;
  }
  .table-width-calc {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
  .target-fulfil {
    position: relative;
    .el-tabs__header {
      width: calc(100% - 70px);
    }
    .tabs-insert-button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .target-fulfil-article {
    padding-left: 70px;
    width: calc(100% - 70px);
    .target-article-item-title {
      display: flex;
      justify-content: space-between;
      .title {
        color: #000;
        font-weight: 700;
      }
      .edit {
        text-align: right;
        width: 200px;
        button {
          padding: 0;
        }
        i {
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
  }
  .target-article-item-content {
    display: flex;
    margin: 30px 0;
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    .img {
      width: 200px;
      height: 120px;
      background: $colorGrey_3;
    }
    .content {
      padding: 0 20px;
    }
  }
  .target-article-item-files {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .target-article-item-file {
      display: flex;
      margin-bottom: 10px;
      margin-right: 10px;
      justify-content: space-between;
      line-height: 67px;
      border-radius: 5px;
      border: 1px solid #f2f2f2;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .type {
        flex-shrink: 0;
        width: 67px;
        height: 67px;
        color: #fff;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
        background: #78aff9;
        border-radius: 5px;
        text-transform: uppercase;
      }
      .file-name {
        padding: 0 20px;
        width: 320px;
        color: $colorGrey_2;
        font-size: 16px;
        font-weight: bold;
      }
      .file-remove {
        padding: 0 30px;
      }
    }
  }
  .target-article-item-add {
    button {
      width: 100%;
    }
  }

  .danger {
    color: #e47470;
  }
}
/deep/.dialog-target-fulfil-add {
  .el-dialog__body {
    padding: 40px 80px 40px 40px;
  }
  .quill-editor {
    .ql-formats {
      line-height: 24px;
    }
    .ql-editor {
      height: 300px;
    }
  }
  .target-fulfil-upload-file {
    display: flex;
    .add {
      margin-left: 20px;
    }
  }
  .size-padding {
    padding: 0 10px;
  }
  .target-fulfil-upload-point {
    text-align: center;
    span {
      color: #c72a29;
    }
  }
  .target-fulfil-files {
    .target-fulfil-files-item {
      display: flex;
      padding: 0 40px;
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      .file {
        display: flex;
        line-height: 57px;
        border: 1px solid #f2f2f3;
        border-radius: 5px;
        .type {
          flex-shrink: 0;
          width: 57px;
          height: 57px;
          border-radius: 5px;
          background: #78aff9;
          color: #fff;
          font-size: 16px;
          text-align: center;
        }
        .file-name {
          flex-grow: 1;
          padding: 0 20px;
          width: 700px;
          color: $colorGrey_2;
          font-size: 16px;
          font-weight: bold;
        }
        .file-remove {
          padding: 0 20px;
        }
      }
      .download {
        padding: 0 30px;
        display: flex;
        align-self: center;
      }
    }
  }
  .el-dialog__footer {
    button {
      padding: 12px 40px;
    }
  }
}
/deep/.dialog-target-description-after {
  .el-textarea__inner {
    height: 160px;
  }
  .el-input__count:before {
    content: '已输入';
  }
}

.practice-files-wrap {
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;
  .file-item {
    margin-right: 80px;
    margin-bottom: 30px;
    border: 1px #e8e8e8 solid;
    border-radius: 4px;
    overflow: hidden;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .file-item-header {
      display: flex;
      padding: 15px;
      height: 80px;
      background: #fff;
      .file-item-header-img {
        > div {
          width: 24px;
          height: 24px;
          background-size: 24px !important;
        }
        .file-pdf {
          background: url(../../../assets/img/file-icon-PDF.png);
        }
        .file-word {
          background: url(../../../assets/img/file-icon-word.png);
        }
        .file-xlsx {
          background: url(../../../assets/img/file-icon-excel.png);
        }
      }
      .file-item-header-name {
        padding-left: 10px;
        width: 200px;
        height: 26px;
        line-height: 26px;
        color: #000;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .file-item-footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 50px;
      &:after {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '';
        width: 1px;
        height: 20px;
        background: #c8c8c8;
        transform: translateY(-50%);
      }
      .el-button {
        width: 50%;
        padding: 3px 10px;
        color: #8c8c8c;
        background: #f7f9fa;
        border-radius: 0;
        &:nth-child(2n) {
          margin-left: 0;
        }
      }
    }
  }
}
.file-preview-dialog {
  /deep/.el-dialog__body {
    padding: 40px;
    video,
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    img {
      width: auto;
      max-width: 100%;
    }
  }
}
</style>
