<template>
  <div>
		<el-dialog
			:visible.sync="dialogVisible"
			:title="dialogTitle"
			id="target-details"
      @close="dialogClose"
			width="90%"
		><!--v-loading="dialogLoading"-->
			<!-- 标题 -->
			<div class="details-title">{{info.infoCode}}:{{info.infoName}}</div>
			<!-- 教育建议 -->
			<div class="details-item">
				<div class="details-item-title">教育建议</div>
				<el-table
					:data="suggests"
					border
				>
					<el-table-column
						label="序号"
						type="index"
						width="100"
						align="center"
					></el-table-column>
					<el-table-column
						label="一级"
					>
						<template slot-scope="data">
							{{suggestsPlaceholder(data, 0)}}
						</template>
					</el-table-column>
					<el-table-column
						label="二级"
						prop=""
					>
            <template slot-scope="data">
							{{suggestsPlaceholder(data, 1)}}
						</template>
          </el-table-column>
					<el-table-column
						label="三级"
						prop=""
					>
            <template slot-scope="data">
							{{suggestsPlaceholder(data, 2)}}
						</template>
          </el-table-column>
				</el-table>
			</div>
      <!-- 指标描述 -->
      <div class="details-item">
        <el-tabs
          v-model="dialogDictsNameValue"
          @tab-click="handleDictTab"
        >
          <el-tab-pane
            v-for="item in dicts"
            :key="item.name"
            :name="item.index"
            :label="item.name"
          >
          </el-tab-pane>
        </el-tabs>

        <div class="details-item-title">指标描述</div>

        <el-table
          :data="desctions"
          border
        >
          <el-table-column
            prop="descriptionCode"
            label="编号"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="descriptionContent"
            label="内容"
          ></el-table-column>
        </el-table>
      </div>

      <!-- 教学实践 -->
      <div class="details-item">
        <div class="details-item-title">教学实践</div>

        <el-tabs
          v-model="dialogTagsNameValue"
          @tab-click="targetFulfilTabClick"
        >
          <el-tab-pane
            v-for="item in tags"
            :key="item.index"
            :name="item.index"
            :label="item.tagName"
          ></el-tab-pane>
        </el-tabs>

        <div class="details-item-article"
          v-for="(item, index) in practices"
          :key="item.id"
        >
          <div class="details-item-article-title">
            <div class="title">{{item.practiceTitle}}</div>
          </div>
          <div class="details-item-article-content">
            <div class="content" v-html="item.practiceContent"></div>
          </div>
          <div class="practice-files-wrap">
          <div
            v-for="(file, fileIndex) in item.files"
            :key="fileIndex"
            class="file-item">
            <div>
              <div class="file-item-header">
                <div class="file-item-header-img">
                  <div
                    v-if="checkFileType(file.originalName, 'ppt')"
                    class="file-ppt" ></div>
                  <div
                    v-if="checkFileType(file.originalName, 'pdf')"
                    class="file-pdf" ></div>
                  <div
                    v-if="checkFileType(file.originalName, 'doc')"
                    class="file-word"></div>
                  <div
                    v-if="checkFileType(file.originalName, 'docx')"
                    class="file-word"></div>
                  <div
                    v-if="checkFileType(file.originalName, 'xlsx')"
                    class="file-xlsx"></div>
                </div>
                <div class="file-item-header-name">{{file.originalName}}</div>
              </div>
              <div class="file-item-footer">
                <template>
                  <el-button
                    @click="targetFulfilUploadFileUpdate(file)"
                    type="text"
                    icon="el-icon-document"
                  ></el-button>
                  <el-button
                    @click="targetArticleItemFilesDelete(item, index, fileIndex)"
                    type="text"
                    icon="el-icon-delete"
                  ></el-button>
                </template>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
		</el-dialog>

    <el-dialog
      title="预览"
      :visible.sync="filePreviewDialog.visible"
      class="target-item-edit file-preview-dialog">
      <img v-if="filePreviewDialog.type === 1" :src="filePreviewDialog.url">
      <video v-if="filePreviewDialog.type === 2" :src="filePreviewDialog.url" controls></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'targetDetails',
  props: {
    targetDetailsData: Object,
    targetDetailsLoading: Boolean,
		targetDetailsVisible: Boolean
  },
	data() {
		return {
      dialogLoading: false,
      pageLoading: null, //页面loading
			dialogTitle: '目标详情',
      dialogVisible: false,
      //字典当前切换下标
      dialogDictsNameValue: '0',
      dialogTagsNameValue: '0',
      dictTagIndex: 0,
			info: {},
			suggests: [],
			dicts: [],
      desctions: [],
			tags: [],
			practices: [],
      filePreviewDialog: {
				visible: false,
				url: '',
				type: 0,
			},
		}
	},
  created() {
		// this.dialogVisible = true;
		// this.getTargetDetails(107);
  },
	methods: {
		getTargetDetails(data) {
      let { info, suggests, dicts, desctions, tags, practices } = data;
      let _suggests = [];
      this.info = info;
      this.suggests = suggests;
      this.dicts = dicts;
      this.desctions = desctions;
      this.tags = tags;
      this.practices = practices;

      this.dialogDictsNameValue = '0';
      this.dialogTagsNameValue = '0';

      if (suggests) {

        suggests.map(item => {
          let _item;
          item.tierIndex = 0;

          _suggests.push(item);

          if (_item = item.children) {
            _item = _item[0];
            _item.tierIndex = 1;
            _suggests.push(_item);

            if (_item = _item.children) {
              _item = _item[0];
              _item.tierIndex = 2;
              _suggests.push(_item);
            }

          }

        });

        this.suggests.splice(0);
        this.suggests.push(..._suggests);
      }

      if (dicts) {

        dicts.map((item, index) => {
          item.index = index.toString();
          return item;
        })

        this.dicts = dicts;

      }
      this.setDataTags(tags);

      this.dialogVisible = true;
		},

    /**
     * 设置教学实践标签数据
     */
    setDataTags(tags) {
      tags.map((item, index) => {
        item.index = index.toString();
        return item;
      });
      this.tags = tags;
    },
    /**
     * 设置教育建议占位符
     * @param {object} data
     * @param {number} index 当前级数
     */
    suggestsPlaceholder(data, index) {
      let { row } = data;
      if (row.tierIndex === index) {
        return row.content;
      } else if (row.tierIndex > index) {
        return '-';
      } else {
        return '/';
      }
    },
    splitSuffix(str) {
			//所选文件的文件名
			let s = str;
			str = str.match(/\.([a-zA-Z]+)$/)
			if (str && str.length >= 2) {
				// 返回文件名后缀
				return str[1]
			}
			return s;
		},
    /**
     * 关闭窗口回调
     * @param {type}
     * @return {type}
     */
    dialogClose() {
      this.dialogLoading = false;
    },
    /**
		 * 检查是否为参数的格式
		 * @param {string} type 文件后缀格式
		 * @return {array|null} 返回匹配到的格式
		 */
		checkFileType(type, strType) {
			let match = type.match(/\.([\w]{3,4})$/);
			if (match) {
				strType = strType.toLowerCase();
				if (match[1] === strType) {
					return 1;
				}
			}
			return 0;
		},
    targetFulfilUploadFileUpdate(file) {
      let fileType = file.fileUrl.match(/\.(\w+)$/);
			fileType = fileType[1];
			switch(fileType) {
				case 'jpg':
				case 'png':
					this.filePreviewDialog.type = 1;
					this.filePreviewDialog.visible = true;
					break;
				case 'mp3':
				case 'mp4':
					this.filePreviewDialog.type = 2;
					this.filePreviewDialog.visible = true;
					break;
				default:
					this.filePreviewDialog.type = 3;
			}
			this.filePreviewDialog.url = file.fileUrl;
			if (this.filePreviewDialog.type === 3) {
				switch (file.isDownload) {
					case '0':
						this.$message.warning('文件设置不可下载');
						break;
					case '1':
						let a = document.createElement('a');
						a.href = file.fileUrl;
						a.click();
						a = null;
				}

			}
		},
    targetArticleItemFilesDelete() {
      this.$message.warning('请在编辑中删除文件');
    },
    //指标描述标签切换
    handleDictTab(data) {
      let { id, flag } = this.info;
      let typeValue = this.dicts[data.name].value;
      this.dictTagIndex = data.name;
      this.$api.getTargetTagDict({ id, flag, typeValue })
				.then(res => {
					if (res.data.code) {
						return this.$message.error('标签指标描述获取失败');
					}

					let { data } = res.data;

					if (data) {
            let { desctions, practices, tags } = data;
            this.desctions = desctions;
            this.practices = practices;
            this.setDataTags(tags);
					}
				})
				.catch(e => {
					this.$message.error('标签指标描述获取错误');
					console.error(e);
				})
    },
    /**
     * 教学实践标签切换
     */
    targetFulfilTabClick(data) {
      let { id, flag } = this.info;
      let typeValue = this.dicts[this.dictTagIndex].value;
      let tagId = this.tags[data.name].id;
      this.$api.getTargetTagPractice({ id, flag, typeValue, tagId })
					.then(res => {
						if (res.data.code) {
								this.$message.error(res.data.msg);
								return;
						}
            this.practices = res.data.data;
					})
					.catch(e => {
						this.$message.error('教育实践标签数据获取失败');
						console.error(e);
					})
    },
	},
	watch: {
		targetDetailsData(data) {
      this.getTargetDetails(data);
		},
    targetDetailsVisible(visible) {
      this.dialogVisible = visible;
    },
    targetDetailsLoading(loading) {
      if(loading){
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        this.pageLoading.close()
      }
      // this.dialogLoading = loading;
    }
	},
	computed: {

	}
}
</script>

<style lang="scss" scoped>
$colorTitle: #666;
$colorBorder: #0075fe;
$colorGrey: #e9e9e9;
#target-details {

	/deep/.el-dialog {
    padding: 20px;
		border-radius:0;
	}

	/deep/.el-dialog__header {
		height: 60px;
		line-height: 60px;
		.el-dialog__title {
			color: $colorTitle;
			font-size: 14px;
		}
    .el-dialog__headerbtn {
      top: 40px;
      right: 46px;
    }
	}

	/deep/.el-dialog__body {
		padding: 20px 60px;

		.details-title {
			color: $colorTitle;
			font-size: 18px;
			font-weight: bold;
			height: 60px;
			line-height: 60px;
			border-bottom: 4px solid $colorBorder;
		}

		.details-item {
			padding: 20px 0;

			.details-item-title {
				margin: 20px 0;
				padding-left: 20px;
				color: $colorTitle;
				font-size: 14px;
				font-weight: bold;
				height: 40px;
				line-height: 40px;
				border-left: 5px solid $colorBorder;
			}

      .details-item-article {
        padding-left: 20px;
        width: calc(100% - 20px);
        .details-item-article-title {
          display: flex;
          justify-content: space-between;
          .title {
            padding-left: 10px;
            height: 40px;
            line-height: 40px;
            color: #000;
            font-weight: 700;
            border-left: 8px solid $colorBorder;
          }
        }
      }
      .details-item-article-content {
        display: flex;
        margin: 30px 0;
        padding: 20px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        .img {
          width: 200px;
          height: 120px;
          background: $colorGrey;
        }
        .content {
          padding: 0 20px;
        }
      }

      .practice-files-wrap {
        display: flex;
        padding: 10px 0;
        flex-wrap: wrap;
        .file-item {
          margin-right: 80px;
          margin-bottom: 30px;
          border: 1px #e8e8e8 solid;
          border-radius: 4px;
          overflow: hidden;
          .file-item-header {
            display: flex;
            padding: 15px;
            height: 80px;
            background: #fff;
            .file-item-header-img {
              >div {
                width: 24px;
                height: 24px;
                background-size: 24px!important;
              }
              .file-ppt{
                background: url(../../../assets/img/file-icon-ppt.png);
              }
              .file-pdf {
                background: url(../../../assets/img/file-icon-PDF.png);
              }
              .file-word {
                background: url(../../../assets/img/file-icon-word.png);
              }
              .file-xlsx {
                background: url(../../../assets/img/file-icon-excel.png);
              }

            }
            .file-item-header-name {
              padding-left: 10px;
              width: 200px;
              height: 26px;
              line-height: 26px;
              color: #000;
              font-size: 18px;
              font-weight: bold;
            }
          }
          .file-item-footer {
            position: relative;
            display: flex;
            justify-content: space-between;
            height: 50px;
            &:after {
              position: absolute;
              left: 50%;
              top: 50%;
              content: '';
              width: 1px;
              height: 20px;
              background: #c8c8c8;
              transform: translateY(-50%);
            }
            .el-button {
              width: 50%;
              padding: 3px 10px;
              color: #8C8C8C;
              background: #f7f9fa;
              border-radius: 0;
              &:nth-child(2n) {
                margin-left: 0;
              }
            }
          }
        }
      }
		}
	}
}
.file-preview-dialog {
  /deep/.el-dialog {
    .el-dialog__body {
      padding: 40px;
      video,
      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }

}
</style>
